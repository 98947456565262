<template>
  <b-container class="create-account-wizard-income create-account-wizard-income-step-03">
    <b-row no-gutters>
      <b-col sm="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5" align-self="start">
        <h2 class="text-dark">
          {{ $t( translation_path + 'title' ) }}
        </h2>
        <p>
          {{ $t( translation_path_step3 + 'intro_p1' ) }}
        </p>
        <i18n :path="translation_path_step3 + 'intro_p2'" tag="p" class="mb-0">
          <b-link slot="help_pages" href="https://forum.spirecta.se/t/aktiva-och-passiva-inkomster/36" target="_blank">{{ $t('common.help_pages') }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" class="accounts_wrapper pt-2 pb-3 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
          <!--Advanced Toggle-->
          <b-form-group
            :label-class="'pr-4 text-black' + (currentLang === 'sv' ? 'pt-2' : 'pt-0')"
            label-cols-lg="4"
            label-align-lg="right"
            :label="$t(translation_path + 'toggle_advanced')"
          >
            <b-form-checkbox v-model="showOptions" name="toggle-button" v-bind:class="currentLang === 'sv' ? 'pt-1 pt-md-3' : 'pt-1 pt-md-3'" size="lg" switch>
            </b-form-checkbox>
          </b-form-group>
          <!--status-->
          <template v-if="showOptions === true">

            <div class="percentageMain">

              <h4>{{ $t(translation_path_step3 + 'active_passive_heading') }}</h4>
              <p>{{ $t(translation_path_step3 + 'active_passive_heading_description') }}</p>

              <b-form-group
                label-class="FormLabels pr-4 text-black"
                label-for="account_percentage"
                label-cols-lg="4"
                label-align-lg="right"
                class="pt-2 pt-md-1 pt-lg-2 pt-xl-3"
                :invalid-feedback="invalidAccountPercentageFeedback"
                :state="!$v.account_percentage.$error"
              >

                <template slot="label">{{ $t(translation_path_step3 + 'percentage_label') }}</template>
                <template slot="description">{{ $t(
                  'transactions.import.import_wizard_v2.step3.percentage_help_text' , {active: account_percentage,
                  passive: passivePercentageValue })}}
                </template>

                <b-col sm="12" md="8" lg="8" xl="9" class="float-left pl-0 pb-2">
                  <b-form-input id="account_percentage" class="slider"
                                type="range" min="0" max="100" step="5"
                                v-model="account_percentage"
                                @input="$v.account_percentage.$touch()"
                  ></b-form-input>
                </b-col>
                <b-col sm="12" md="4" lg="4" xl="3" class="float-left mb-2 pl-0 pr-0">
                  <b-input-group append="%">
                    <b-form-input id="account_percentage" type="number" min="0" max="100"
                                  v-model="account_percentage"
                                  @input="$v.account_percentage.$touch()"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-form-group>
            </div>
            <h4>{{ $t(translation_path_step3 + 'compare_yourself_heading') }}</h4>
            <p>{{ $t(translation_path_step3 + 'compare_yourself_description') }}</p>
          <b-form-group
            label-class="pt-0 pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
          >
            <template slot="label">
              <i18n :path="translation_path + (currentUser.strict_accounting_mode ? 'global_account': 'global_category')">
              </i18n>
            </template>
            <custom-select rounded
              v-model="global_id"
              :options="globalAccountsOptions"
              groupingValues="accounts"
              groupingLabel="label"
              :placeholder="currentUser.strict_accounting_mode ? $t(translation_path + 'select_global_account') : $t(translation_path + 'select_global_category')"
              :search-placeholder="$t('common.type_to_filter')"
              :noResultText="$t('common.no_option_found')"
              :loading="loadingGlobalAccounts"
              :sortGroupLabel="false"
              :enableDiselect="true"
              label="label"
            />
          </b-form-group>

            <h4>{{ $t(translation_path + 'other_options') }}</h4>
            <p>{{ $t(translation_path_step3 + 'other_options_description') }}</p>

            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              :state="$v.status.$dirty ? !$v.status.$error : null"
              :invalid-feedback="invalidStatusFeedback"
          >
            <template slot="label">{{ $t(translation_path + 'status_label') }}</template>
            <b-form-select v-model="status" id="status" :options="aStatusOptions" :state="$v.status.$dirty ? !$v.status.$error : null" @input="optionChangeHandler" class="rounded-pill"></b-form-select>
          </b-form-group>

          <!-- CAPITAL ACCOUNT -->
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            :state="$v.is_capital_account.$dirty ? !$v.status.$error : null"
            :invalid-feedback="invalidCapitalAccountFeedback"
          >
            <template slot="label">{{ $t(translation_path + 'capital_account_label') }}</template>
            <template slot="description">{{ $t(translation_path + 'capital_account_description') }}</template>
            <b-form-select v-model="is_capital_account" id="is_capital_account" :options="aCapitalAccountOptions" :state="$v.is_capital_account.$dirty ? !$v.is_capital_account.$error : null" @input="optionChangeHandler" class="rounded-pill"></b-form-select>
          </b-form-group>

          <!-- ACCOUNT_CODE -->
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-for="account_code"
            label-cols-lg="4"
            label-align-lg="right"
            :invalid-feedback="invalidAccountCodeFeedback"
            :state="!$v.account_code.$error"
            >
            <template slot="label">
              <i18n :path="translation_path + 'code_label'"></i18n>
            </template>
            <template slot="description">{{ $t(translation_path + 'code_help_text') }} {{ $t(translation_path + 'code_help_text_recommendation_'+typeOfAccount ) }}</template>
            <b-form-input id="account_code" class="rounded-pill"
                          :placeholder="$t(translation_path + 'code_placeholder')"
                          :state="$v.account_code.$dirty ? !$v.account_code.$error : null"
                          v-model="account_code"
                          @input="$v.account_code.$touch()"
            ></b-form-input>
          </b-form-group>

          <!-- DESCRIPTION -->
          <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-for="description"
              label-cols-lg="4"
              label-align-lg="right"
            >
              <template slot="label">{{ $t(translation_path + 'description_label') }}</template>
              <template slot="description">{{ $t(translation_path + 'description_help_text') }}</template>
              <b-form-textarea v-model="description"></b-form-textarea>
            </b-form-group>
          </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import GlobalsMixins from '@/mixins/globals'
import IncomeExpenseAccountMixins from '@/mixins/IncomeExpenseAccount'
import CustomSelect from '@/components/common/CustomSelect'

export default {
  name: 'account-wizard-step03',
  components: { CustomSelect },
  mixins: [GlobalsMixins, IncomeExpenseAccountMixins],
  data () {
    return {
      account_percentage: 80
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">
.create-account-wizard-income-step-03 {
  .form-control[readonly]{
    background-color:#fff;
  }
  .percentageMain {
    display: inline-block;
    width: 100%;
  }
  h4{margin-top:25px;padding-top:25px;border-top:1px dashed gray}
  #account_percentage{
    padding-right: 2px;
    text-align: right;
    @media screen and (max-width:767.98px) {
      margin-top: 0px;
      margin-bottom: 15px;
      text-align:left;
    }
  }
}
</style>
