<template>
  <b-container class="account-wizard bg-light page-wrapper account-wizard-income">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        <h1 class="text-left" v-if="!isUpdate">{{ $t( translationPath + (currentUser.strict_accounting_mode ? 'create_account' : 'create_category' )) }}{{ h1AccountTitle }}</h1>
        <h1 class="text-left" v-if="isUpdate">{{ $t( translationPath + (currentUser.strict_accounting_mode ? 'edit_account' : 'edit_category' )) }}{{ h1AccountTitle }}</h1>
        <div class="step-container-v2">
          <div class="stepper bg-light mx-auto text-regular">

            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="step">
              <tab-content :title="$t(translationPath+ 'wizard_steps.step1_general_info')" :before-change="()=>validateStep('step1')">
                <account-wizard-step01 ref="step1"  @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :isUpdate="isUpdate"></account-wizard-step01>
              </tab-content>
              <tab-content :title="$t(translationPath+ 'wizard_steps.step2_category_group')" :before-change="()=>validateStep('step2')">
                <account-wizard-step02 ref="step2" @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :isUpdate="isUpdate"></account-wizard-step02>
              </tab-content>
              <tab-content :title="$t(translationPath+ 'wizard_steps.step3_advanced_options')" :before-change="()=>validateStep('step3')">
                <account-wizard-step03 ref="step3" @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :finalModel="finalModel" v-bind:totalRows="5" :isUpdate="isUpdate"></account-wizard-step03>
              </tab-content>
              <template slot="footer" slot-scope="props">
                <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                  <div class=wizard-footer-left>
                    <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.previous') }}
                    </wizard-button>
                  </div>
                  <div class="line" v-if="props.activeTabIndex !== 0"></div>
                  <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="props.activeTabIndex === 1 && nextDisabled" tabindex="0">
                      {{ $t('common.next') }}
                    </wizard-button>
                    <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.save') }}
                    </wizard-button>
                  </div>
                </div>
              </template>
            </form-wizard>

            <success-modal v-if="!isUpdate"
              :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account' : 'title_category'),{account: this.finalModel.account_title})"
              :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account' : 'message_category'),{account: this.finalModel.account_title})"
              :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview' : 'button_category_overview'),{account: this.finalModel.account_title})"
              :redirect="'/reports/other/account-plan-v2/'+typeOfAccount+'s'"
              :orLink="'/redirect?to=accountsCreate&accountType='+typeOfAccount"
              :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_create_account' : 'or_create_category'))"
              @hide="$router.push('/redirect?to=accountsCreate&accountType='+typeOfAccount)"
            ></success-modal>
            <success-modal v-if="isUpdate"
              :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account_update' : 'title_category_update'),{account: this.finalModel.account_title})"
              :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account_update' : 'message_category_update'),{account: this.finalModel.account_title})"
              :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview_update' : 'button_category_overview_update'),{account: this.finalModel.account_title})"
              :redirect="'/reports/other/account-plan-v2/'+typeOfAccount+'s'"
              :orLink="'/reports/performance/accounts/' + iAccountId+'/view'"
              :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_open_account_report' : 'or_open_category_report'))"
              @hide="$router.push('/reports/performance/accounts/' + iAccountId+'/view')"
            ></success-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AccountWizardStep01 from './income/AccountWizardStep01'
import AccountWizardStep02 from './income/AccountWizardStep02'
import AccountWizardStep03 from './income/AccountWizardStep03'
import SuccessModal from '@/components/modals/SuccessModal'
import titleMixins from '@/mixins/title'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from 'axios'
import EventBus from '@/event-bus'
import { mapState } from 'vuex'

export default {
  name: 'wizardAccountIncome',
  mixins: [titleMixins],
  data () {
    return {
      nextDisabled: false,
      finalModel: {},
      isCompleted: false,
      h1AccountTitle: null,
      isUpdate: false,
      editAccountDetails: {},
      translationPath: 'incomes_expenses.accounts.create_account_wizard.common.',
      translationPathModal: 'incomes_expenses.accounts.create_account_wizard.income.success_modal.',
      isStep3Ok: null,
      typeOfAccount: 'income',
      iAccountId: null
    }
  },
  components: {
    SuccessModal,
    FormWizard,
    TabContent,
    WizardButton,
    AccountWizardStep01,
    AccountWizardStep02,
    AccountWizardStep03
  },
  created () {
    EventBus.$on('group_selected', status => {
      this.nextDisabled = !status
    })
    if (this.$route.name === 'incomes-accounts-edit') {
      this.isUpdate = true
      this.loadAccountDetails()
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.manage_title'), to: '/reports/other/account-plan-v2/incomes' },
        { text: this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'accounts' : 'categories')), to: '/incomes-expenses/accounts/' + this.typeOfAccount },
        { text: this.$t(this.accountTypeTranslationString), to: '/reports/other/account-plan-v2/incomes' },
        { text: '', active: true }
      ]
    },
    accountTypeTranslationString () {
      return (this.typeOfAccount) ? this.translationPath + this.typeOfAccount : this.translationPath + 'income'
    },
    titleMeta () {
      if (this.isUpdate) {
        return this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'edit_account' : 'edit_category'))
      }
      return this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'create_account' : 'create_category'))
    }
  },
  methods: {
    loadAccountDetails () {
      const accountId = (Object.keys(this.$route.params).length > 0) ? (Object.prototype.hasOwnProperty.call(this.$route.params, 'account_id') ? this.$route.params.account_id : null) : null
      this.getAccountDetails(accountId)
        .then(responseData => responseData.data.data)
        .then(data => {
          this.editAccountDetails = data
          this.typeOfAccount = data.type
          this.iAccountId = data.id
          Object.assign(this.finalModel, { account_title: data.title })
        })
        .catch(err => {
          console.error(err)
        })
    },
    validateStep (name) {
      const selectedAccountGroup = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && this.finalModel.selected_account && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'id') ? this.finalModel.selected_account.id : null
      if (name === 'step2' && !this.isUpdate && selectedAccountGroup) {
        this.$refs.step3.suggestionCode(selectedAccountGroup)
      }
      return this.$refs[name].validate()
    },
    async onComplete () {
      var self = this

      await this.validateStep('step3')
        .then(function (response) {
          self.isStep3Ok = response
        })
        .catch(function (error) { console.error(error) })

      if (self.isStep3Ok) {
        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts' + (this.isUpdate ? '/' + this.$route.params.account_id : '')
        const sApiMethod = this.isUpdate ? 'put' : 'post'

        await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: this.getWizardData()
        })
          .then(function (response) {
            self.$bvModal.show('successModal')
            if (self.$route.name === 'incomes-accounts-edit') {
              self.loadAccountDetails()
            }
          })
          .catch(function (error) {
            console.error(error)
          })
      }
    },
    mergePartialModels (model) {
      // merging each step model into the final model
      this.finalModel = Object.assign({}, this.finalModel, model)
    },
    getWizardData () {
      return {
        group_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'id') && this.finalModel.selected_account.id ? this.finalModel.selected_account.id : null,
        title: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_title') && this.finalModel.account_title ? this.finalModel.account_title : null,
        type: Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'type') && this.finalModel.selected_account.type ? this.finalModel.selected_account.type : null,
        status: Object.prototype.hasOwnProperty.call(this.finalModel, 'status') && this.finalModel.status ? this.finalModel.status : null,
        description: Object.prototype.hasOwnProperty.call(this.finalModel, 'description') ? this.finalModel.description : null,
        is_capital_account: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_capital_account') ? this.finalModel.is_capital_account : null,
        default_active_percentage: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_percentage') ? this.finalModel.account_percentage : null,
        code: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_code') ? this.finalModel.account_code : null,
        is_change_of_value: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_change_of_value') && this.finalModel.is_change_of_value ? this.finalModel.is_change_of_value : 0,
        global_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'global_id') && this.finalModel.global_id ? this.finalModel.global_id : null
      }
    },
    async getAccountDetails (accountId) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/${accountId}`)
    }
  },
  watch: {
    finalModel () {
      this.h1AccountTitle = ': "' + this.finalModel.account_title + '"'
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
@import '@/assets/scss/modal.scss';

.create-account-wizard-income {
  padding:0;
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left:none;
    }
  }
}
</style>
